// Gallery.js
import React from "react";

import PublishedPhotos from "../Components/Published";

function Gallery() {
  return (
    <div>
      <PublishedPhotos />
    </div>
  );
}

export default Gallery;
