import React from "react";

function Home() {
  return (
    <div className="home-container">
      {" "}
      <h1>Manto Kamari</h1>
    </div>
  );
}

export default Home;
